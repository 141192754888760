import React from 'react';
import { Paper } from '@mui/material';
import { API_SUCCESS_CODES, LABELS, pulseSurveyTypes } from '../../../../global-constants';
import { useDispatch, useSelector } from 'react-redux';
import { ViewAllModal } from '../../../../common/DialogModal';
import PageHeader from './common-components/PageHeader';
import ModuleCard from './OHI-4.0/ModuleCard';
import styled from 'styled-components';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CommonLabel } from '../../../../common/FormInputs/Common';
import { FlexColumnGap, FlexRow } from '../../../../common/styled/styled';
import PssQuestions from './QuestionModules/PssQuestions';
import {
    updatePssQuestion,
    updatePssModule,
    setSurveySliceLoading,
} from '../../../../store/reducers/slice/SurveySlice';
import TEIModule from './QuestionModules/TEIModule/index';
import c from 'classnames';
import { setToast } from '../../../../store/reducers/slice/Toast';

const OptionalModulesSection = ({
    isHeaderRequired = true,
    addRemoveModule,
    styles,
    valuesData = {},
    pssData,
    handleViewAll,
    handleQuestionResponse,
    updatePssQuestions,
    teiData,
    handleModuleUpdate,
    handleQuestionUpdate,
}) => {
    const dispatch = useDispatch();
    const { ADDITIONAL_TTILE, ADDITIONAL_DESC } = LABELS.SURVEY_MODULE.OPTIONAL_MODULES;
    const { surveyInformation: si = {} } = useSelector(state => state.surveyReducer);
    const [open, setOpen] = React.useState(false);
    const [tabId, setTabId] = React.useState(null);

    const showOutcomes = pulseSurveyTypes.includes(si?.schema_name);

    const openModal = () => {
        setOpen(true);
    };
    const closeModal = () => {
        setOpen(false);
    };

    const {
        questions: questions,
        indexToUse: selectedSectionIndex,
        tab_key: selectedSectionKey,
    } = React.useMemo(() => {
        if (pssData?.uuid) {
            const indexToUse = pssData.sections.findIndex(({ tab_key }) => tab_key === tabId);
            const { questions = [], tab_key } = pssData.sections[indexToUse] || {};
            return {
                questions,
                indexToUse,
                tab_key,
            };
        }
        return {
            questions: [],
            indexToUse: 0,
        };
    }, [pssData, tabId]);

    const handleParentCheck = async ({ checked }, is_social_sector = false, updateStore = true) => {
        dispatch(setSurveySliceLoading(true));
        const resp = await updatePssQuestions(checked, is_social_sector);
        if (resp && API_SUCCESS_CODES.includes(resp?.status) && updateStore) {
            await dispatch(updatePssModule({ updateSocial: is_social_sector, checked }));
            dispatch(setToast({ show: true, type: 'success', message: 'Additional module saved successfully' }));
        }
        return resp;
    };

    return (
        <>
            <FlexColumnGap gap={pxToVh(40)} width="100%">
                {valuesData?.length || pssData?.uuid ? (
                    <Paper
                        elevation={2}
                        sx={{ ...styles, alignSelf: 'stretch' }}
                        className={c('outcomesAndPractices', {
                            notPulse: teiData?.length,
                        })}
                    >
                        {isHeaderRequired && !showOutcomes ? (
                            <PageHeader title={ADDITIONAL_TTILE} desc={ADDITIONAL_DESC} />
                        ) : null}
                        <Modulebody>
                            <CommonLabel
                                textWeight={500}
                                textSize={18}
                                lineHeight={28}
                                label="Optional modules"
                                textColor={colorsCode.TextHeading}
                            />
                        </Modulebody>
                        <Modulebody style={{ paddingTop: '0vh', paddingBottom: `${pxToVh(40)}vh` }}>
                            <FlexRow gap={pxToVw(12)}>
                                {valuesData.length > 0
                                    ? valuesData.map((item, index) => {
                                          return (
                                              <ModuleCard
                                                  key={item.id}
                                                  isValues={!index}
                                                  item={item}
                                                  index={index}
                                                  addRemoveModule={addRemoveModule}
                                                  handleViewAll={handleViewAll}
                                                  btnlabel={!index ? 'Preview' : ''}
                                              />
                                          );
                                      })
                                    : null}
                                {pssData?.uuid ? (
                                    <ModuleCard
                                        key={pssData.id}
                                        item={pssData}
                                        index={0}
                                        handleViewAll={handleViewAll}
                                        desc={pssData?.module_desc}
                                        handleParentCheck={handleParentCheck}
                                    />
                                ) : null}
                            </FlexRow>
                        </Modulebody>
                        {pssData?.uuid && isHeaderRequired ? (
                            <PssQuestions
                                pssData={pssData}
                                updatePssQuestion={updatePssQuestion}
                                tabId={tabId}
                                setTabId={setTabId}
                                questions={questions}
                                selectedSectionIndex={selectedSectionIndex}
                                handleParentCheck={handleParentCheck}
                                handleQuestionResponse={handleQuestionResponse}
                                selectedSectionKey={selectedSectionKey}
                            />
                        ) : null}
                    </Paper>
                ) : null}
                {teiData?.length ? (
                    <TEIModule
                        teiData={teiData}
                        isHeaderRequired={isHeaderRequired}
                        addRemoveModule={addRemoveModule}
                        styles={styles}
                        handleModuleUpdate={handleModuleUpdate}
                        handleQuestionUpdate={handleQuestionUpdate}
                    />
                ) : null}
            </FlexColumnGap>
            {open && (
                <ViewAllModal
                    title={'Value questions'}
                    description="Includes questions regarding values and beliefs that underpin organizational performance. User must select values that describe client name."
                    list={valuesData[0]?.sections}
                    open={openModal}
                    close={closeModal}
                />
            )}
        </>
    );
};

export const Modulebody = styled.div`
    padding: ${pxToVh(40)}vh ${pxToVw(40)}vw ${pxToVh(20)}vh ${pxToVw(40)}vw;
    gap: ${({ gap }) => (gap ? `${gap}vw` : null)};
`;

export default OptionalModulesSection;
