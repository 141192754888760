import { createSlice, current } from '@reduxjs/toolkit';
import {
    GetSurveyInformation,
    GetAllSurveys,
    GetAdditionalModules,
    GetAllQuesUnderModule,
    GetAllSurveysListing,
    SaveSurveyStatus,
    GetAllSurveyListAction,
    getAllSurveysCount,
    DeleteSurvey,
    updateBulkSurvey,
    UpdateAdditionalModules,
    DownloadMonitorFiles,
    ExportAllSurvey,
    UpdateTeiQuestion,
    UpdateTeiModule,
} from '../../actions/Surveys/action';
import { SURVEY_STATUS } from '../../../global-constants';
import { cloneDeep } from 'lodash';

export const INITIAL_STATE = {
    loading: false,
    error: false,
    errorMessage: '',
    surveyInformation: {},
    allSurveysList: {
        reviewed: [],
        draft: [],
        closed: [],
        allData: [],
        under: [],
    },
    mySurveyList: {
        reviewed: [],
        draft: [],
        closed: [],
        live: [],
        underreview: [],
        allData: [],
    },
    allSurveys: [],
    isDataFetched: false,
    additionalModules: [],
    allQues: [],
    allcount: '',
    surveyError: false,
    allSurveysLoading: true,
    optionalModule: [],
    iswordingcall: false,
    dataExported: false,
    isPssLoading: false,
    checkIsAssigned: false,
    stopCheckIsAssigned: false,
    surveyExportLoader: false,
};

const SurveyObject = createSlice({
    name: 'SurveyReducer',
    initialState: INITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(GetAllSurveys.pending, state => {
                state.loading = true;
                state.allSurveysLoading = true;
            })
            .addCase(GetAllSurveys.fulfilled, (state, { payload }) => {
                const {
                    status = '',
                    data: {
                        data: { records = [], total = 0 },
                    },
                } = payload;
                if (status === SURVEY_STATUS.LIVE) {
                    state.mySurveyList.live = payload.data;
                } else if (status === SURVEY_STATUS.COMPLETED) {
                    state.mySurveyList.closed = payload.data;
                } else if (status === SURVEY_STATUS.DRAFT) {
                    state.mySurveyList.draft = payload.data;
                } else if (status === SURVEY_STATUS.REVIEWED) {
                    state.mySurveyList.reviewed = payload.data;
                } else if (status === SURVEY_STATUS.UNDER) {
                    state.mySurveyList.underreview = payload.data;
                } else {
                    state.mySurveyList.allData = {
                        records: records.length ? records.map(item => ({ ...item, is_selected: false })) : [],
                        total,
                    };
                }
                state.loading = false;
                state.allSurveysLoading = false;
            })
            .addCase(GetAllSurveys.rejected, state => {
                state.loading = false;
                state.allSurveysLoading = false;
            })
            .addCase(GetSurveyInformation.pending, state => {
                state.loading = true;
                state.surveyError = false;
            })
            .addCase(GetSurveyInformation.fulfilled, (state, { payload }) => {
                state.loading = false;
                const copy = cloneDeep(payload.data);
                if (payload?.isDuplicate) {
                    copy.salesforce_id = '';
                    copy.supported_project_managers = [];
                }
                state.surveyInformation = copy;
                state.isDataFetched = true;
                state.surveyError = false;
            })
            .addCase(GetSurveyInformation.rejected, state => {
                state.loading = false;
                state.surveyError = true;
            })
            .addCase(DeleteSurvey.pending, state => {
                state.loading = true;
            })
            .addCase(DeleteSurvey.fulfilled, state => {
                state.loading = false;
            })
            .addCase(DeleteSurvey.rejected, state => {
                state.loading = false;
            })
            .addCase(GetAdditionalModules.pending, state => {
                state.loading = true;
            })
            .addCase(GetAdditionalModules.fulfilled, (state, { payload }) => {
                const updatedAdditionalModules = payload?.records || [];
                updatedAdditionalModules.forEach(({ sub_group_type, sections = [] }, moduleIndex) => {
                    if (sub_group_type === 'tei_module') {
                        updatedAdditionalModules[moduleIndex].is_included = false;
                        for (let i = 0; i < sections.length; i++) {
                            const { use_in_survey } = sections[i];
                            if (use_in_survey) {
                                updatedAdditionalModules[moduleIndex].is_included = true;
                                break; // Complete module is selected if any of the question is selected
                            }
                        }
                    }
                });
                state.loading = false;
                state.additionalModules = updatedAdditionalModules;
                state.isDataFetched = true;
            })
            .addCase(GetAdditionalModules.rejected, state => {
                state.loading = false;
            })
            .addCase(GetAllQuesUnderModule.pending, state => {
                state.loading = false;
            })
            .addCase(GetAllQuesUnderModule.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.allQues = payload?.records;
                state.isDataFetched = true;
            })
            .addCase(GetAllQuesUnderModule.rejected, state => {
                state.loading = false;
            })
            .addCase(GetAllSurveysListing.pending, state => {
                state.loading = true;
            })
            .addCase(GetAllSurveysListing.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.allSurveys = payload.data;
            })
            .addCase(GetAllSurveysListing.rejected, state => {
                state.loading = false;
            })
            .addCase(SaveSurveyStatus.pending, state => {
                state.loading = true;
            })
            .addCase(SaveSurveyStatus.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(SaveSurveyStatus.rejected, state => {
                state.loading = false;
            })
            .addCase(GetAllSurveyListAction.pending, state => {
                state.loading = true;
                state.allSurveysLoading = true;
            })
            .addCase(GetAllSurveyListAction.fulfilled, (state, { payload }) => {
                if (payload.status === SURVEY_STATUS.COMPLETED) {
                    state.allSurveysList.closed = payload.data;
                } else if (payload.status === SURVEY_STATUS.DRAFT) {
                    state.allSurveysList.draft = payload.data;
                } else if (payload.status === SURVEY_STATUS.REVIEWED) {
                    state.allSurveysList.reviewed = payload.data;
                } else if (payload.status === SURVEY_STATUS.UNDER) {
                    state.allSurveysList.under = payload.data;
                } else {
                    let data = payload.data?.data?.records?.length ? payload.data?.data?.records : [];
                    data.map((item, i) => {
                        return Object.assign(data[i], { is_selected: false });
                    });
                    const ObjectResponse = {
                        records: data,
                        total: payload.data?.data?.total || 0,
                    };
                    state.allSurveysList.allData = ObjectResponse;
                    state.mySurveyList.allData = payload.data?.data;
                }
                state.loading = false;
                state.allSurveysLoading = false;
            })
            .addCase(GetAllSurveyListAction.rejected, state => {
                state.loading = false;
                state.allSurveysLoading = false;
            })
            .addCase(getAllSurveysCount.pending, state => {
                state.loading = true;
            })
            .addCase(getAllSurveysCount.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.allcount = payload.data;
                if (payload.checkIsAssigned && !state.stopCheckIsAssigned) {
                    state.stopCheckIsAssigned = true;
                    state.checkIsAssigned = Object.values(payload.data)?.some(el => el);
                }
            })
            .addCase(getAllSurveysCount.rejected, state => {
                state.loading = false;
            })
            .addCase(updateBulkSurvey.pending, state => {
                state.loading = true;
            })
            .addCase(updateBulkSurvey.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(updateBulkSurvey.rejected, state => {
                state.loading = false;
            })
            .addCase(UpdateAdditionalModules.pending, state => {
                state.loading = true;
            })
            .addCase(UpdateAdditionalModules.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.optionalModule = payload;
            })
            .addCase(UpdateAdditionalModules.rejected, state => {
                state.loading = false;
            })
            .addCase(UpdateTeiQuestion.pending, state => {
                state.loading = true;
            })
            .addCase(UpdateTeiQuestion.fulfilled, state => {
                state.loading = false;
            })
            .addCase(UpdateTeiQuestion.rejected, state => {
                state.loading = false;
            })
            .addCase(UpdateTeiModule.pending, state => {
                state.loading = true;
            })
            .addCase(UpdateTeiModule.fulfilled, state => {
                state.loading = false;
            })
            .addCase(UpdateTeiModule.rejected, state => {
                state.loading = false;
            })
            .addCase(DownloadMonitorFiles.pending, state => {
                state.loading = true;
            })
            .addCase(DownloadMonitorFiles.fulfilled, (state, { payload }) => {
                state.loading = false;
                if (payload?.success) state.dataExported = true;
                state.errorMessage = payload?.message;
            })
            .addCase(DownloadMonitorFiles.rejected, state => {
                state.loading = false;
            })
            .addCase(ExportAllSurvey.pending, state => {
                state.surveyExportLoader = true;
            })
            .addCase(ExportAllSurvey.fulfilled, state => {
                state.surveyExportLoader = false;
            })
            .addCase(ExportAllSurvey.rejected, state => {
                state.surveyExportLoader = false;
            });
    },
    reducers: {
        resetData: state => {
            state.surveyError = false;
            state.iswordingcall = false;
        },
        setSurveyInformation: (state, { payload }) => {
            state.surveyInformation = payload;
            state.isDataFetched = true;
        },
        resetSurveyInfo: state => {
            state.surveyInformation = {};
            state.additionalModules = [];
            state.isDataFetched = false;
            state.surveyError = false;
            state.iswordingcall = false;
        },
        updateModule: (state, { payload }) => {
            const { id, flag, isTeiModule } = payload;
            const copy = cloneDeep(current(state).additionalModules);
            const findIndex = copy.findIndex(({ uuid }) => id === uuid);
            copy[findIndex].is_included = flag;

            if (isTeiModule) {
                copy[findIndex].sections.forEach(question => {
                    question.use_in_survey = flag;
                });
            }
            state.additionalModules = copy;
        },

        updateQuestion: (state, { payload }) => {
            const { flag, questionIndex, moduleIndex } = payload;
            const copy = cloneDeep(current(state).additionalModules);
            const updatedQuestions = copy[moduleIndex].sections;
            updatedQuestions[questionIndex].use_in_survey = flag;
            const selectedQuesLen = updatedQuestions.filter(({ use_in_survey }) => use_in_survey).length;
            copy[moduleIndex].is_included = !!selectedQuesLen;
            copy[moduleIndex].sections = updatedQuestions;
            state.additionalModules = copy;
        },
        updateTabQuestions: (state, { payload }) => {
            const { tabId, secIndx, checked } = payload;
            const tabIndex = state.additionalModules.findIndex(({ id }) => id === tabId);
            state.additionalModules[tabIndex].sections[secIndx].is_included = checked;
            state.additionalModules[tabIndex].sections[secIndx].questions = state.additionalModules[tabIndex].sections[
                secIndx
            ].questions.map(oldQuestion => ({
                ...oldQuestion,
                use_in_survey: checked,
            }));
        },
        updatePssModule: (state, { payload }) => {
            const { checked, updateSocial } = payload;
            const tabIndex = state.additionalModules.findIndex(
                ({ sub_group_code }) => sub_group_code === 'pss_questions'
            );
            state.loading = false;

            const { is_social_included, sections } = state.additionalModules[tabIndex];

            const selectedKey = updateSocial ? 'social_sector' : 'public_sector';
            const questionsPosition = sections.findIndex(({ tab_key }) => tab_key === selectedKey);
            if (updateSocial) {
                state.additionalModules[tabIndex].is_social_included = checked;
            } else {
                //unselect social sector module and all its questions
                if (is_social_included && !checked) {
                    state.additionalModules[tabIndex].is_social_included = false;

                    const questionsPosition = sections.findIndex(({ tab_key }) => tab_key === 'social_sector');

                    state.additionalModules[tabIndex].sections[questionsPosition].questions = sections[
                        questionsPosition
                    ].questions.map(oldQuestion => ({
                        ...oldQuestion,
                        use_in_survey: checked,
                    }));
                }
                state.additionalModules[tabIndex].is_included = checked;
            }

            state.additionalModules[tabIndex].sections[questionsPosition].questions = sections[
                questionsPosition
            ].questions.map(oldQuestion => ({
                ...oldQuestion,
                use_in_survey: checked,
            }));
        },
        updateSectionQuestion: (state, { payload }) => {
            const { tabId, secIndx, qIndex, checked } = payload;
            const tabIndex = state.additionalModules.findIndex(({ id }) => id === tabId);
            state.additionalModules[tabIndex].sections[secIndx].questions[qIndex].use_in_survey = checked;
        },
        updatePssQuestion: (state, { payload }) => {
            const { updatedPssModule } = payload;
            const tabIndex = state.additionalModules.findIndex(
                ({ sub_group_code }) => sub_group_code === 'pss_questions'
            );
            state.additionalModules[tabIndex] = updatedPssModule;

            state.loading = false;
        },
        updateWording: (state, { payload }) => {
            state.iswordingcall = payload;
        },
        resetDataExported: state => {
            state.dataExported = false;
        },
        setSurveySliceLoading: (state, { payload }) => {
            state.loading = payload;
        },
        updateSurveyMeta: (state, { payload }) => {
            const { name, value } = payload;
            const copy = cloneDeep(state.surveyInformation.survey_meta);
            copy.map(item => {
                if (item.meta_key === name) {
                    item.meta_value = value;
                }
                return item;
            });
            state.surveyInformation.survey_meta = copy;
        },
        updateSurveyInfo: (state, { payload }) => {
            const { name, value } = payload;
            state.surveyInformation[name] = value;
        },
    },
});
export const {
    setSurveyInformation,
    resetData,
    resetSurveyInfo,
    updateModule,
    updateTabQuestions,
    updateSectionQuestion,
    updateWording,
    resetDataExported,
    setSurveySliceLoading,
    updatePssQuestion,
    updatePssModule,
    updateSurveyMeta,
    updateSurveyInfo,
    updateQuestion,
} = SurveyObject.actions;
export default SurveyObject.reducer;
