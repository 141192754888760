import { createAsyncThunk } from '@reduxjs/toolkit';
import { Endpoints } from '../../../http/Endpoints';
import { api } from '../../../http/api';
import { MESSAGE, PATHS, SURVEY_STATUS } from '../../../global-constants';
import { openModal } from '../../reducers/slice/Modal';
import AuthStore from '../../../common/AuthStore';
import { downloadFile } from '../../../utils/functions';
import { updateModule, updateQuestion } from '../../reducers/slice/SurveySlice';
import { setToast } from '../../reducers/slice/Toast';

export const UserTemplates = createAsyncThunk(
    '/api/survey-template-listing',
    async (obj, { rejectWithValue, dispatch }) => {
        try {
            const response = await api.get(Endpoints.userTemplates);
            const { data } = response;
            return data;
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const SurveyTemplatesLang = createAsyncThunk(
    'api/languages/temp',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const response = await api.get(Endpoints.surveyTemplates.replace(':tid', params.tid));
            const { data } = response;
            return data;
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const ExportAllSurvey = createAsyncThunk('exportallsurvey', async (params, { rejectWithValue, dispatch }) => {
    const { self, dateRange, reqBody, searchVal } = params;

    try {
        const endPoint = Endpoints.exportAllSurveys.concat(
            dateRange.startDate
                ? `?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}&self=${self}`
                : `?self=${self}&q=${searchVal}`
        );

        const response = await api.post(endPoint, reqBody, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        const today = new Date();
        const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        link.download = `SurveyExport_${date}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message);
    }
});
export const GetAllSurveys = createAsyncThunk('getsurveysall', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { limit, page, q, startDate, endDate, status = '', reqBody = '' } = params;

        let url = `${Endpoints.surveyList}?limit=${limit}&page=${page}`;
        if (q) {
            url = url.concat(`&q=${encodeURIComponent(q)}`);
        }
        if (startDate) {
            url = url.concat(`&start_date=${startDate}`);
        }
        if (endDate) {
            url = url.concat(`&end_date=${endDate}`);
        }
        let bodyObj = {
            status: status.length && Array.isArray(status) ? status : status && !Array.isArray(status) ? [status] : [],
            link_type: [],
            template_type: [],
            survey_language: [],
        };
        if (reqBody) {
            const {
                status: status1 = '',
                link_type = '',
                template_type = '',
                survey_language = '',
                is_test = '',
                is_combined_survey = '',
                maintenance_mode = '',
            } = reqBody;
            bodyObj.status = status1.length ? status1 : [];
            bodyObj.link_type = link_type.length ? link_type : [];
            bodyObj.template_type = template_type.length ? template_type : [];
            bodyObj.survey_language = survey_language.length ? survey_language : [];
            if (typeof is_test == 'boolean') {
                bodyObj.is_test = is_test;
            }
            if (typeof is_combined_survey == 'boolean') {
                bodyObj.is_combined_survey = is_combined_survey;
            }
            if (typeof maintenance_mode == 'boolean') {
                bodyObj.maintenance_mode = maintenance_mode;
            }
        }
        const response = await api.post(url, { ...bodyObj });
        const { data } = response;
        return { data, status };
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const GetSurveyInformation = createAsyncThunk('surveyInfo', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { survey_id, history = () => {}, isDuplicate = false } = params;
        const url = `${Endpoints.createSurvey}/${survey_id}`;
        const response = await api.get(url);
        const {
            data: { data = {} },
        } = response;
        if (typeof history !== 'undefined') {
            history(PATHS.CREATE_SURVEY);
        }
        return { data, isDuplicate };
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const DeleteSurvey = createAsyncThunk('deleteSurvey', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { state, survey_id } = params;
        const status = AuthStore.isAdminOrCoach
            ? SURVEY_STATUS.DRAFT
            : [SURVEY_STATUS.DRAFT, SURVEY_STATUS.UNDER, SURVEY_STATUS.REVIEWED];
        const url = `${Endpoints.createSurvey}/${survey_id}`;
        const response = await api.delete(url);
        await dispatch(GetAllSurveys({ limit: state.limit, page: state.page, status }));
        dispatch(setToast({ show: true, type: 'success', message: MESSAGE.success_delete_survey }));
        return response?.data || {};
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const GetAdditionalModules = createAsyncThunk(
    'additionalQues',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { survey_id, is_survey_setup } = params;
            const url = Endpoints.getAdditionalModules
                .replace(':survey_id', survey_id)
                .concat('?is_optional=true')
                .concat(is_survey_setup ? '&is_survey_setup=true' : '');
            const response = await api.get(url);
            const {
                data: { data = {} },
            } = response;
            return data;
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const UpdateAdditionalModules = createAsyncThunk(
    'updateAdditionalQues',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { survey_id, ques_sub_group_id, is_include, isTeiModule } = params;
            const url = `${Endpoints.updateAdditionalModules
                .replace(':survey_id', survey_id)
                .replace(':question_sub_group_id', ques_sub_group_id)}?include=${is_include}`;
            const response = await api.put(url);
            dispatch(updateModule({ id: ques_sub_group_id, flag: is_include, isTeiModule }));
            //fetch updated additonal modules
            // await dispatch(GetAdditionalModules({ survey_id, ques_sub_group_id }));
            const {
                data: { data = {} },
            } = response;
            dispatch(setToast({ show: true, type: 'success', message: 'Additional module saved successfully' }));
            return data;
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const GetAllQuesUnderModule = createAsyncThunk('getAllQts', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { survey_id, ques_sub_group_id } = params;
        const url = Endpoints.viewAllQUES
            .replace(':survey_id', survey_id)
            .replace(':question_sub_group_id', ques_sub_group_id);
        const response = await api.get(url);
        const { data } = response;
        return data;
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const UpdateTeiModule = createAsyncThunk(
    'updateAdditionalModule',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { survey_id, moduleUuid, is_include, isTeiModule, questionUuids } = params;
            const url = `${Endpoints.updateMultipleQuestions
                .replace(':survey_id', survey_id)
                .concat('?is_standard=false')}`;
            const response = await api.post(url, {
                use_in_survey: is_include,
                question_ids: questionUuids,
            });
            dispatch(updateModule({ id: moduleUuid, flag: is_include, isTeiModule }));
            const {
                data: { data = {} },
            } = response;
            dispatch(setToast({ show: true, type: 'success', message: 'Additional module saved successfully' }));
            return data;
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const UpdateTeiQuestion = createAsyncThunk(
    'updateAdditionalModuleQuestion',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { survey_id, is_include, questionIndex, moduleIndex, questionUuid } = params;
            const url = `${Endpoints.saveStandardDemo
                .replace(':survey_id', survey_id)
                .replace(':question_id', questionUuid)}`;

            const response = await api.patch(url, { use_in_survey: is_include });
            dispatch(updateQuestion({ flag: is_include, questionIndex, moduleIndex }));
            const {
                data: { data = {} },
            } = response;
            dispatch(setToast({ show: true, type: 'success', message: 'Additional module saved successfully' }));
            return data;
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const GetAllSurveysListing = createAsyncThunk(
    'getsurveysallliting',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { limit, page, q } = params;
            let url = `${Endpoints.createSurvey}?limit=${limit}&page=${page}`;
            if (q) {
                url = `${url}&q=${encodeURIComponent(q)}`;
            }
            const response = await api.get(url);
            const { data } = response;
            return data;
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const SaveSurveyStatus = createAsyncThunk('surveystatus', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { surveyId, status, isSurveyInfoReq = true, showSuccessMessage, isFinalizeScreen } = params;
        const response = await api.patch(
            Endpoints.surveyStatusUrl.replace(':survey_id', surveyId).concat('?survey_status=', status),
            {}
        );
        const { data } = response;
        const { message = '' } = data;
        if (showSuccessMessage) {
            dispatch(setToast({ show: true, type: 'success', message: message }));
        }
        if (status !== SURVEY_STATUS.LIVE && isSurveyInfoReq) {
            dispatch(GetSurveyInformation({ survey_id: surveyId }));
        } else {
            dispatch(openModal());
        }
        if (isFinalizeScreen) {
            dispatch(setToast({ show: true, type: 'success', message: MESSAGE.QUESTION_REVIEWED }));
        }
        return data;
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});
export const UpdateMaintenanceMode = createAsyncThunk('changeMaintenanceMode', async (params, { rejectWithValue }) => {
    try {
        const { surveyId, maintenance_mode } = params;
        const response = await api.patch(Endpoints.maintenanceMode.replace(':survey_id', surveyId), {
            maintenance_mode,
        });
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const GetAllSurveyListAction = createAsyncThunk(
    'get-all-surveys',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { limit, page, q, searchParam = '', dateRange, status = '', reqBody = '' } = params;
            const searchQuery = searchParam ? `?q=${encodeURIComponent(searchParam)}` : '';
            const startDateQuery = dateRange?.startDate
                ? searchParam
                    ? `&start_date=${dateRange?.startDate}`
                    : `?start_date=${dateRange?.startDate}`
                : '';
            const endDateQuery = dateRange?.endDate ? `&end_date=${dateRange?.endDate}` : '';

            const appendUrl =
                searchQuery || startDateQuery ? `&limit=${limit}&page=${page}` : `?limit=${limit}&page=${page}`;

            let url = `${Endpoints.getAllSurveys}${searchQuery}${startDateQuery}${endDateQuery}${appendUrl}`;
            if (q) {
                url = `${url}&q=${encodeURIComponent(q)}`;
            }
            let bodyObj = {
                status: status ? [status] : [],
                link_type: [],
                template_type: [],
                survey_language: [],
            };

            if (reqBody) {
                const {
                    status: status1 = '',
                    link_type = '',
                    template_type = '',
                    survey_language = '',
                    is_combined_survey = '',
                    is_test = '',
                    maintenance_mode = '',
                } = reqBody;
                bodyObj.status = status1.length ? status1 : [];
                bodyObj.link_type = link_type.length ? link_type : [];
                bodyObj.template_type = template_type.length ? template_type : [];
                bodyObj.survey_language = survey_language.length ? survey_language : [];
                if (typeof is_test == 'boolean') {
                    bodyObj.is_test = is_test;
                }
                if (typeof is_combined_survey == 'boolean') {
                    bodyObj.is_combined_survey = is_combined_survey;
                }
                if (typeof maintenance_mode == 'boolean') {
                    bodyObj.maintenance_mode = maintenance_mode;
                }
            }
            const response = await api.post(url, { ...bodyObj });
            const { data } = response;
            return { data, status, searchParam };
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const getAllSurveysCount = createAsyncThunk('surveycount', async (params, { rejectWithValue, dispatch }) => {
    const { is_test_survey, checkIsAssigned } = params;
    try {
        const response = await api.get(
            `/api/survey/survey-count${
                typeof is_test_survey === 'boolean' ? `?is_test_survey=${is_test_survey}&limit=10&page=1` : ''
            }`
        );
        const { data } = response;
        return { data, checkIsAssigned };
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const updateBulkSurvey = createAsyncThunk('updateBulkSurvey', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { surveyIds, status, tab, state } = params;
        const reqBody = {
            survey_ids: [...surveyIds],
        };
        const response = await api.post(Endpoints.updateBulkSurvey.concat('?survey_status=', status), reqBody);
        const { data } = response;
        if (tab === 1) {
            await dispatch(GetAllSurveyListAction({ ...state }));
        } else {
            await dispatch(GetAllSurveys({ ...state }));
        }
        return data;
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const DownloadMonitorFiles = createAsyncThunk(
    'download file for monitor',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { surveyId, slug, langid } = params;
            const url = !langid
                ? Endpoints.exportAsnwerQuestions.replace(':survey_id', surveyId).concat(`?data_export_type=${slug}`)
                : Endpoints.exportFile.replace(':survey_id', surveyId).replace(':langid', langid);
            const response = await api.get(url);
            const {
                data: { data },
            } = response;
            downloadFile(data);
            return response.data;
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const removeReasonEmail = createAsyncThunk('ReasonEmail', async (params, { rejectWithValue }) => {
    try {
        const { survey_id, reason, section } = params;
        const url = `${Endpoints.createSurvey}/${survey_id}/notify?section=${section}`;
        await api.post(url, { type: 'EMAIL', reason: reason });
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const getGlossary = createAsyncThunk('getGlossary', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { surveyId } = params;
        const url = Endpoints.getGlossary.replace(':survey_id', surveyId);
        const response = await api.get(url);
        const {
            data: { data },
        } = response;
        return data;
    } catch (error) {
        dispatch(
            setToast({
                show: true,
                type: 'error',
                message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
            })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const updateGlossary = createAsyncThunk('updateGlossary', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { surveyId, uuid, update_obj } = params;
        const url = Endpoints.updateGlossary.replace(':survey_id', surveyId).replace(':uuid', uuid);
        const response = await api.put(url, update_obj);
        const {
            data: { data },
        } = response;
        let message = '';
        if (update_obj?.field === 'order') {
            message = 'Glossary reordered successfully';
        } else if (update_obj?.field === 'is_deleted') {
            message = 'Glossary deleted successfully';
        } else {
            message = 'Glossary updated successfully';
        }
        dispatch(setToast({ show: true, type: 'success', message: message }));
        return { data, update_obj };
    } catch (error) {
        dispatch(
            setToast({ show: true, type: 'error', message: error?.response?.data?.message || MESSAGE.Error_Add_Client })
        );
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const addCustomGlossary = createAsyncThunk(
    'addCustomGlossary',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { surveyId, glossary, index } = params;
            const url = Endpoints.getGlossary.replace(':survey_id', surveyId);
            const response = await api.post(url, glossary);
            const {
                data: { data },
            } = response;
            dispatch(setToast({ show: true, type: 'success', message: 'Glossary updated successfully' }));
            return { data, index };
        } catch (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: error?.response?.data?.message || MESSAGE.Error_Add_Client,
                })
            );
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);
